
// Define multiple base URLs as an object
const BASE_URLS = {

  // Server link
  // main: 'http://localhost/websites/b2c_stripe/webadmin/api/',
  main: 'https://trypexpert.ruzinn.com/webadmin/api/',
  webUrl: '/',
  
  payUrl: 'https://trypexpert.ruzinn.com/',
  // payUrl: 'http://localhost/websites/b2c_stripe/',
};
export default BASE_URLS;
